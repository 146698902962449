.client-settings-container {
  span {
    .fa-spinner {
      width: 100%;
      text-align: center;
      vertical-align: middle;
      margin-top: 2rem;
    }
  }
}
