.popup {
    position: absolute;
    top: 0;
    z-index: 99999;
    background: $white;
    // display: none;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    width: 410px;
    right: 10px;
    padding: 15px;
    color: #343434;
    max-width: 95%;
    @include mq(md) {
        max-width: none;
    }
    h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 5px;
        text-align: left;
    }
    p {
        font-size: 16px;
        text-align: left;
    }
    .popup-body {
        display: flex;
        flex-direction: row;
        .icon-holder {
            margin-right: 10px;
            .icon {
                font-size: 48px;
                color: #fd0000;
            }
        }
    }
    .popup-footer {
        display: flex;
        justify-content: flex-end;
        .btn {
            margin-left: 10px;
        }
    }
}
tr:nth-child(4) {
    td .popup {
        display: block;
    }
}
.has-popup {
    @include mq(md) {
        position: relative;
    }
}
.delete-popup {
    top: 150px;
    left: 50%;
    width: 300px;
    transform: translate(-50%, -50%);
    @include mq(md) {
        right: auto;
    }
}
