.pretty {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    line-height: 0.79;
    input {
     position: absolute;
     left: 0;
     top: 0;
     min-width: 1em;
     z-index: 2;
     opacity: 0;
     margin: 0;
     padding: 0;
     cursor: pointer;
     &:not([type="checkbox"]) {
      display: none;
     }
    }
   
    .state {
     label {
      position: initial;
      display: inline-block;
      font-size: 1em;
      font-weight: 400;
      margin: 0;
      text-indent: 0.2em;
      min-width: calc(0.8em + 1px);
      &:after,
      &:before {
       content: "";
       width: calc(0.8em + 1px);
       height: calc(0.8em + 1px);
       display: block;
       box-sizing: border-box;
       border-radius: 0;
       border: 1px solid transparent;
       z-index: 0;
       position: absolute;
       left: 0;
       top: calc((0% - (100% - 1em)) - 8%);
       background-color: transparent;
      }
      &:before {
       border-color: #c0c0c0;
      }
     }
    }
    &.p-default input:checked ~ .state label:before {
     border-color: #ff8121;
    }
    .state {
     &.p-is-hover,
     .p-is-indeterminate {
      display: none;
     }
    }
    &.p-default {
     &.p-fill {
      .state {
       label {
        &:after {
         -webkit-transform: scale(1);
         -ms-transform: scale(1);
         transform: scale(1);
        }
       }
      }
     }
    }
   
    &.p-default {
     .state {
      label {
       &:after {
        -webkit-transform: scale(0.6);
        -ms-transform: scale(0.6);
        transform: scale(0.6);
       }
      }
     }
     input:checked ~ .state label:after {
      background-color: #ff8121 !important;
     }
     &.p-thick {
      .state {
       label {
        &:after,
        &:before {
         border-width: calc(1em / 7);
        }
        &:after {
         -webkit-transform: scale(0.4) !important;
         -ms-transform: scale(0.4) !important;
         transform: scale(0.4) !important;
        }
       }
      }
     }
    }
   }
   


   .tbl-checkbox {
    .styled-input-single {
        position: relative;
        padding: 5px 0 5px 24px;
        text-align: left;
      
        label {
          cursor: pointer;
          &:before,
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            border-radius: 50%;
          }
          &:before {
            left: 0;
            width: 17px;
            height: 17px;
            margin: -10px 0 0;
            background: transparent;
            // box-shadow: 0 0 1px black;
            border: 1px solid #ff8121;
          }
          &:after {
            left: 3px;
            width: 11px;
            height: 11px;
            margin: -7px 0 0;
            opacity: 0;
            background: #ff8121;
            // transform: translate3d(-40px, 0, 0) scale(0.5);
            // transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
          }
        }
      
        input[type="radio"],
        input[type="checkbox"] {
          position: absolute;
          top: 0;
          left: -9999px;
          visibility: hidden;
      
          &:checked + label {
            &:before {
            }
            &:after {
              transform: translate3d(0, 0, 0);
              opacity: 1;
            }
          }
        }
      }
      
      .styled-input--square {
        label {
          &:before,
          &:after {
            border-radius: 0;
          }
        }
      }
      
      .styled-input--rounded {
        label {
          &:before {
            border-radius: 10px;
          }
          &:after {
            border-radius: 6px;
          }
        }
      }
      
      .styled-input--diamond {
        .styled-input-single {
          padding-left: 45px;
        }
        label {
          &:before,
          &:after {
            border-radius: 0;
          }
          &:before {
            transform: rotate(45deg);
          }
        }
      
        input[type="radio"],
        input[type="checkbox"] {
          &:checked + label {
            &:after {
              transform: rotate(45deg);
              opacity: 1;
            }
          }
        }
      }
      
   }