.bp-btn {
    background: $white;
    border-radius: 30px;
    padding: 0px 20px;
}
.btn-success{
    color: #28a745;
}
.btn-danger{
    color: #dc3545;
}
.btn-base {
    display: block;
    width: 180px;
    padding: 7px 10px;
    background: #232323;
    color: $white;
    mix-blend-mode: normal;
    border-radius: 30px;
    text-align: center;
    &:focus,
    &:hover {
        outline: none;
        color: $white;
    }
}
.btn-container {
    background: $white;
    padding-left: 0;
    float: right;
    z-index: 9;
    position: relative;
    @include mq(md) {
        padding-left: 30px;
    }
}

.btn-add {
    display: block;
    padding: 1px 20px;
    background: $white;
    color: $black;
    border-radius: 30px;
    text-align: center;
    border: 1px solid $black;
    &:focus,
    &:hover {
        outline: none;
        background: $black;
        color: $white;
        box-shadow: none;
    }
    .icon {
        font-size: 14px;
    }
}
.btn-setting{
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 30px;
    padding: 1px 15px;
    cursor: pointer;
    color: #212529;
    display: inline-block;
      &:hover, &:focus{
        background: #000;
        border:1px soid $black;
        color: #fff;
    }
    a{
        color: #212529;
          &:hover, &:focus{
        background: #000;
        border:1px soid $black;
        color: #fff;
    }
  
    }
}