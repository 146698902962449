// ==========================================================================
// Helpers
// Helpers classes such as object classes used for floating an element left and right,
// text alignments etc. are included here.
// Simple helper classes are generally referred using class selectors and their css properties
// will have an !important property attached to it, so that a helper class cannot be overwritten.
// Placeholders can also be included in the helper partial for these purposes.
// ==========================================================================

//Screen reader text:
.srt{
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
%clearfix-none{
	display: inherit;
}
%pseudos{
	display: block;
	content:"";
	position: absolute;
}
%shadow{
  -webkit-box-shadow: 0px 8px 12px -6px transparentize($black, 0.8);
  -moz-box-shadow: 0px 8px 12px -6px transparentize($black, 0.8);
  box-shadow: 0px 8px 12px -6px transparentize($black, 0.8);
}
%ellipsis{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
