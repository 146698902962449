$sizes: 50;

@mixin margin-classes {
    @for $i from 1 through $sizes {
        $margin: $i + px;

        /* margin #{$margin} */
        .m#{$i} {
            margin: $margin;
        }

        .ml#{$i} {
            margin-left: $margin;
        }

        .mr#{$i} {
            margin-right: $margin;
        }

        .mt#{$i} {
            margin-top: $margin;
        }

        .mb#{$i} {
            margin-bottom: $margin;
        }
    }
}

@include margin-classes;

@mixin padding-classes {
    @for $i from 1 through $sizes {
        $padding: $i * 0.25rem;

        /* padding #{$padding} */
        .p#{$i} {
            padding: $padding;
        }

        .pl#{$i} {
            padding-left: $padding;
        }

        .pr#{$i} {
            padding-right: $padding;
        }

        .pt#{$i} {
            padding-top: $padding;
        }

        .pb#{$i} {
            padding-bottom: $padding;
        }
    }
}

@include padding-classes;