.nav {
    .nav-item {
        .nav-link {
            border-radius: 0;
            color: #495057;
            &.active {
                font-weight: bold;
                color: #495057;
            }
        }
    }
}

.tab-content {
    border: 1px solid #dee2e6;
    border-top: none;
    width: 100%;

    .custom-select {
        margin-top: 5px;
    }

    .list-group-item {
        padding: .5rem 1.25rem;
    }
    
    .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: #000000;
        background-color: #000000;
    }

    .custom-switch {
        .custom-control-label {
            font-weight: normal;
        }
    }
    p {
        margin-bottom: 0;
    }
    .jumbotron {     
        .lead {
            font-size: 0.8rem;
            font-weight: 300;
            text-transform: uppercase;
            color: #495057;
            border-left: 1px solid #c3c3c3;
            padding-left: 60px;
            strong {
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }
}
