.client-settings-container {
    .Clone-Tab-Contents {
        border: 1px solid #dee2e6;
        border-top: 0;
        padding: 8px 16px;

        .haslink {
            border: 1px dotted #dee2e6;
            border-left: 0;
            border-right: 0;
            padding: 5px 0;
        }

        .haslink:first-child {
            border-top: 0;
        }

        .searchbox {
            input {
                display: block;
                width: 100%;
                height: calc(1.5em + 0.75rem + 2px);
                padding: 0.375rem 0.75rem;
                font-size: 1rem;
                font-weight: 400;
                line-height: 1.5;
                color: #495057;
                background-color: #fff;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0.25rem;
                transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

                &:focus {
                    color: #495057;
                    background-color: #fff;
                    border-color: #80bdff;
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
                }
            }
        }
    }

    .table-responsive {

        table {
            input[type=checkbox] {
                -ms-transform: scale(1.5); /* IE */
                -moz-transform: scale(1.5); /* FF */
                -webkit-transform: scale(1.5); /* Safari and Chrome */
                -o-transform: scale(1.5); /* Opera */
                transform: scale(1.5);
            }
        }
    }

    
    .dropdown .search-bar {
        padding-left: 62px;
        &::before {
            left: 30px;
            top: 10px;
        }
    }
    .dropdown-menu {
        width: 100%;
    }
    .dropdown-toggle, .custom-select {
        margin-top: 10px;
        padding: 5px;
        height: 30px;
        font-size: 14px;
        line-height: 14px;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #d0d0d0;
        appearance: none;
        background: #fff;
        display: inline-block;
        width: 100%;
        padding: 0.375rem 0;
        color: #495057;
        vertical-align: middle;

        &:after {
            float: right;
            // margin-top: 10px;
            content: '\67';
            font-family: baseplatform;
            border: none;
        }
    }   



}

/* ========================================================================== */
/* Modal Design */
/* ========================================================================== */
.modal {
    align-items: center!important;
    display: flex!important;
    
    .modal-content {
        padding: 10px 32px;
        // border-radius: 0;
    }
    
    .modal-title {
        font-weight: bold;
    }
    
    .modal-header {
        padding: 1rem 0rem;
        border-bottom: 2px solid black;
    }
    
    .modal-body {
        padding: 1rem 0;
    }
    
    .modal-footer {
        padding: .75rem 0;
        border-top:0;
    }
    
    Button {
        color: #fff;
        border-radius: 0;

    }
    Button.close {
        color: black!important;
    }        
    

}