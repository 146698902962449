.barchart {
    .card-body {
        display: flex;
        flex-direction: column;
        @include mq(md) {
            flex-direction: row;
        }
    }
    &-right {
        display: block;
        flex-direction: row;
        margin-bottom: -1px;
        margin-left: -16px;
        margin-right: -16px;

        @include mq(md) {
            margin-left: auto;
            margin-bottom: 10px;
            margin-right: 0;
        }
        .block {
            border: 1px solid #dddddd;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 10px;
            span {
                font-size: 28px;
                font-weight: bold;
                &.name {
                    font-size: 16px;
                    margin-bottom: 2px;
                    font-weight: normal;
                }
            }
            &:nth-child(1) {
                margin-right: -1px;
            }
            &:nth-child(1),
            &:nth-child(2) {
                width: 50%;
                float: left;
                margin-bottom: -1px;
                @include mq(md) {
                    width: auto;
                    float: unset;
                    margin-bottom: 10px;
                }
            }
        }
    }
    &-chart {
        @include mq(md) {
            width: calc(100% - 200px);
        }
    }
}
.pie-chart {
    margin-bottom: 20px;
}
.active-clients {
    td {
        min-width: 200px;
        @include mq(md) {
            min-width: auto;
        }
    }
}
.piechart {
    .chart-footer {
        border: none;
    }
}
