.user-options {
    display: flex;
    align-items: center;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    padding: 10px 10px;
    background: #1e1e1e;
    color: #fff;
    @include mq(md) {
        margin-left: auto;
        box-shadow: none;
        padding: 0;
        background: #fff;
        color: #343434;
    }
       .dropdown-item{
            padding:.25rem 1rem;
            &:last-child{
                color:#fd0000;
            }
        }
    .logo {
        height: 36px;
        padding: 0;
        margin-left: 10px;
        min-width: 100px;
        @include mq(md) {
            display: none;
        }
        img {
            height: 100%;
        }
    }
    .icon {
        padding: 0 10px;
        line-height: 1;
        font-size: 20px;
    }
    .user {
        .btn {
            padding: 0;
            color: $white;
            background: transparent;
            border: none;
            &:active,
            &:focus {
                color: $white;
                border: none;
                background: transparent;
                outline: none;
                box-shadow: none !important;
            }
            & > .icon {
                font-size: 32px;
            }
            @include mq(md) {
                color: $black;
                &:active,
                &:focus {
                    color: $black;
                }
            }
        }
        .dropdown-menu {
            left: auto;
            right: 0;
            width: 190px;
            border-radius: 0;
            top: 10px !important;
            a {
                color: #000000;
            }
        }
    }
    .options {
        display: flex;
        margin-left: auto;
        & > div {
            align-items: center;
            display: flex;
        }
        .notification{
            position: relative;
            .badge{
                position: absolute;
                top: 1px;
                right: 8px;
                border-radius: 20px;
                width: 16px;
                padding: 0.2em;
                height: 16px;
            }
            }
    }
    .menu {
    background: rgba(255, 255, 255, 0.1);
    padding: 6px 12px;
    border-radius: 2px;
    font-size: 1rem;
        @include mq(md) {
            display: none;
        }
    }
            .icon{
            &-view, &-lock, &-logout{
        font-size: 1rem !important;
        padding: 0;
        }
        }
}
