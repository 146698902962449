.pagination {
    float: right;
    font-size: 15px;
    span {
        color: $gray;
        font-weight: 200;
        padding:0 5px;
    }
    li {
        display: inline-block;
        padding:0 5px;
        
        a {
            color: #343434;
            font-weight: 500;
            &.disable {
                color: #ccc;
            }
            &.active{
                border: 1px solid #ff8121;
                font-weight: 600;
                color: #ff8121;
                padding: 0 5px;
            } 
            &:hover{
                color: #ff8121;
                font-weight: 600
            }
            i{
                margin-top: -2px;
            }
        }
    }
}
