.bp-form {
  label {
    font-size: 16px;
    color: $black;
    font-weight: bold;
  }
  input {
    border: none;
    background: transparent;
    border-radius: 0;
    padding-left: 2px;
    color: #343434;
    border-bottom: 1px solid #ddd;
    &:active,
    &:focus {
      background: transparent;
      outline: none;
      box-shadow: none;
      border-color: #ff8121;
    }
  }
  a {
    color: $black;
  }
  .form-control:disabled,
  .form-control[readonly] {
    background: none;
  }
  .custon-checkbox {
    float: left;
    margin-right: 5px;
    width: 20px;
    position: relative;
    span {
      &:before {
        font-family: fontawesome;
        content: '\f096'; //box
      }
    }
    input[type='checkbox'] {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      margin: 0;
      &:checked {
        & ~ span:before {
          content: '\f046'; //checked
        }
      }
    }
  }
  .rdtPicker {
    .rdtDays {
      .rdtDay.rdtActive.rdtDisabled {
        background: #eee;
      }
    }
  }
}
