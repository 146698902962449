.header {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    background: $white;
    display: flex;
    background: #fff;
    display: flex;
    position: fixed;
    z-index: 999;
    right: 0;
    left: 0;
    flex-direction: column;
    @include mq(md){
        left: 92px;
        flex-direction: row-reverse;
        padding: 0 20px;
    }
   
}
.title {
    position: relative;
    margin-bottom: 20px;
    span {
        display: block;
    }
    h1 {
        background: #fff;
        z-index: 9;
        position: relative;
        padding-right: 20px;
        display: inline-block;
    }
    @include mq(md) {
    &:before {
        position: absolute;
        content: "";
        width: 100%;
        border-bottom: 1px solid #000;
        bottom: 12px;
    }
    &:after {
        position: absolute;
        content: "";
        width: 30%;
        border-bottom: 3px solid #000;
        bottom: 11px;
        min-width: 250px;
    }
}
}
