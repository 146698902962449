.log-details{
    max-width: 650px;
    margin: 3.8rem 0 1.75rem auto;
    .modal-content{
        padding: 30px;
        .modal-header{
            padding: 0 0 1rem;
            border-bottom: 2px solid #1e1e1e;
            h5{
                font-weight: 600;
            }
        }
    }
}