.client-settings-sidebar {
  background: rgba(244, 245, 247, 0.6);
  border-right: 1px solid rgba(221, 221, 221, 0.6);
  display: flex;
  flex-direction: column;
  @include mq(lg) {
    width: 270px;
    margin-bottom: -88px;
  }
  .client-select {
    padding: 10px 20px;
    border-bottom: 1px solid #dddddd;
    @include mq(lg) {
      padding: 20px;
    }
  }
}
.clent-setting-navbar {
  padding: 16px 20px;
  .heading {
    font-size: 18px;
    font-weight: bold;
  }
  ul {
    text-align: center;
    @include mq(lg) {
      text-align: left;
    }
    li {
      display: inline-block;
      width: 54px;
      height: 54px;
      border-radius: 50%;
      text-align: center;
      border: 1px solid #eaeaea;
      margin-right: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-right: 0;
      }
      @include mq(lg) {
        margin-right: 0;
        width: auto;
        height: auto;
        border-radius: 0;
        display: block;
        border: none;
        position: relative;
        margin-bottom: 0;
      }
      a,
      .haslink {
        display: block;
        height: 100%;
        vertical-align: middle;
        color: #343434;
        padding: 3px 0px;
        @include mq(lg) {
          text-align: left;
          padding: 10px 12px;
        }

        .icon {
          display: block;
          font-size: 26px;
          margin: 0;
          @include mq(lg) {
            font-size: 16px;
            margin-right: 8px;
            display: inline-block;
          }
        }
        span {
          display: none;
          font-size: 9px;
          @include mq(lg) {
            font-size: 16px;
            display: inline;
            vertical-align: bottom;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
        &.active {
          background: #1e1e1e;
          color: $white;
          border-radius: 50px;
          @include mq(lg) {
            border-radius: 0;
          }
        }
        &.delete {
          color: #fd0000;
          &:hover {
            background: #fd0000;
            color: $white;
          }
        }
      }
    }
  }
}
.submenu-footer {
  span {
    &:hover {
      color: #ff8121;
    }
  }
}
