.width-140 {
  width: 140px;
}
.clients-action {
  color: #212529;
  position: absolute;
  min-width: 10rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #212529;
  text-align: center;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  cursor: pointer;
  z-index: 99;
  right: 10px;
  a {
    color: #212529;
  }
}
td {
  span {
    padding: 4px 8px;
    max-width: 300px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}
td.copy {
  span {
    max-width: 300px;
  }
}
.copy {
  span {
    max-width: 100%;
    border: 1px solid transparent;
    padding: 4px 25px 4px 5px;
    color: #1e1e1e;
    display: inline-block;
    // max-width: 300px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    position: relative;
    &:after {
      content: '\31';
      font-family: 'baseplatform';
      display: inline-block;
      margin-left: 5px;
      visibility: hidden;
      position: absolute;
      right: 5px;
      top: 5px;
    }
    &:hover,
    &:focus {
      background: #f8f8f8;
      border-color: #ddd;
      cursor: pointer;
      &:after {
        content: '\31';
        font-family: 'baseplatform';
        display: inline-block;
        margin-left: 5px;
        visibility: visible;
      }
    }
  }
}

td {
  p span {
    padding: 0;
    max-width: 300px;
    display: inline;
  }
}
