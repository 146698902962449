.breadcrumb {
    background: #f1f1f1;
    margin: 0;
    white-space: nowrap;
    padding: 0;
    border-radius: 0;
    display: none;
    @include mq(md) {
        background: $white;
        margin-left: 5px;
        display: block;
    }
    li {
        padding: 0 15px 2px 20px;
        display: inline-block;
        line-height: 26px;
        margin: 0 9px 0 -10px;
        position: relative;
        font-size: 14px;
        color: #332e38;
        &:last-child{
            padding-left: 30px;
        }
        @include mq(md) {
            padding: 15px 15px 16px 15px;
        }
        a {
            
            color: #1e1e1e;
            font-weight: 600;
            cursor: pointer;
            z-index: 999;
            position: relative;
            &:hover{
                color: #ff8121;
            }
        }

        &:before,
        &:after {
            border-right: 1px solid #e0e0e0;
            content: "";
            display: block;
            height: 50%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            z-index: -1;
            transform: skewX(45deg);
            z-index: 1;
        }
        &:after {
            bottom: 0;
            top: auto;
            transform: skewX(-45deg);
        }

        &:last-of-type::before,
        &:last-of-type::after {
            display: none;
        }
    }
}
