.auth-page {
  // background: linear-gradient(54.58deg, #ff8121 0%, #ff005c 100%);
  background: linear-gradient(54.58deg, #ff8121 0%, #1e1e1e 100%);
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  @include mq(md) {
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 350px solid transparent;
      border-bottom: 350px solid transparent;
      border-left: 300px solid rgba(255, 255, 255, 0.05);
      position: absolute;
    }
    &:after {
      width: 0;
      height: 0;
      border-left: 950px solid transparent;
      border-right: 350px solid transparent;
      border-bottom: 350px solid rgba(255, 255, 255, 0.05);
      position: absolute;
      bottom: 0;
    }
  }
}
.auth-container {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 9;
  padding: 20px;
  @include mq(md) {
    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 250px solid transparent;
      border-bottom: 550px solid transparent;
      border-right: 300px solid rgba(255, 255, 255, 0.05);
      position: absolute;
      right: 0;
      top: -30%;
    }
  }
  .auth {
    width: 400px;
    margin: 0 auto;
    max-width: 100%;
    color: $white;
    border-radius: 4px;
    @include mq(md) {
      background: $white;
      color: $black;
      padding: 30px;
    }
    form {
      margin: 40px 0;
      label,
      a {
        color: #fff;
        @include mq(md) {
          color: $black;
        }
      }
      .with-icon {
        position: relative;
        &:before {
          position: absolute;
          left: 10px;
          top: 6px;
          font-family: 'baseplatform' !important;
          color: #fff;
          @include mq(md) {
            content: '' !important;
          }
        }
        &.username:before {
          content: '\45';
        }
        &.password:before {
          content: '\6c';
        }
        &.email:before {
          content: '\58';
        }
        input {
          border: 1px solid #fff;
          border-radius: 30px;
          padding-left: 35px;
          color: $white;
          &:placeholder {
            color: #fff;
            opacity: 1;
          }

          @include mq(md) {
            border-radius: 0;
            padding-left: 5px;
            border: none;
            border-bottom: 1px solid #bababa;
            color: $black;
          }
        }
        label {
          display: none;
          @include mq(md) {
            display: block;
          }
        }
      }
    }
  }
  h1 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  span {
    font-size: 18px;
    font-weight: normal;
  }
}
.copyright {
  color: $white;
  align-self: center;
}
.copyright {
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
}

.change-password {
  padding: 20%;
  @include mq(md) {
    padding: 10% 20%;
  }
  .wrap-box {
    max-width: 450px;
    margin: 0 auto;
  }

  h2 {
    margin: 0 0 20px 15px;
  }

  .change-button {
    margin-left: 15px;
  }
}
