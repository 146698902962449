.client-select {
  position: relative;
  img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    object-fit: contain;
    box-shadow: 1px 1px 5px #ddd;
    margin-right: 5px;
  }
  .display-tablee {
    display: table;
    width: 94%;
    img,
    span {
      vertical-align: middle;
    }
  }
  i {
    position: absolute;
    right: 0.7rem;
    top: 0.8rem;
    font-size: 1.3rem;
    @include mq(lg) {
      top: 1.3rem;
    }
  }
}

.client-dropdown {
  position: absolute;
  top: 100%;
  background: #fff;
  padding: 10px 15px;
  left: 0;
  right: 0;
  z-index: 99;
  //   display: none;
  box-shadow: 1px 1px 4px #ccc;
  height: 100vh;
}

.client-list-dropdown {
  padding: 10px 0;
  .list {
    padding: 5px;
    display: flex;
    // border-bottom: 1px solid #dddddd;
    &:hover {
      background: rgba(244, 245, 247, 0.6);
    }
    a {
      color: #343434;
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
      &:hover {
        color: #000;
      }
    }

    img {
      margin-right: 5px;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      object-fit: contain;
      box-shadow: 1px 1px 5px #ddd;
    }
    span {
      align-self: center;
    }
  }
}
