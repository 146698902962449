@charset "UTF-8";

@font-face {
  font-family: "baseplatform";
  src:url("fonts/baseplatform.eot");
  src:url("fonts/baseplatform.eot?#iefix") format("embedded-opentype"),
    url("fonts/baseplatform.woff") format("woff"),
    url("fonts/baseplatform.ttf") format("truetype"),
    url("fonts/baseplatform.svg#baseplatform") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "baseplatform" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "baseplatform" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\61";
}
.icon-bitcoin:before {
  content: "\63";
}
.icon-controls:before {
  content: "\65";
}
.icon-danger:before {
  content: "\66";
}
.icon-down-arrow:before {
  content: "\67";
}
.icon-edit:before {
  content: "\69";
}
.icon-export:before {
  content: "\6a";
}
.icon-file:before {
  content: "\6b";
}
.icon-lock:before {
  content: "\6c";
}
.icon-menu:before {
  content: "\6d";
}
.icon-minus:before {
  content: "\6e";
}
.icon-logout:before {
  content: "\6f";
}
.icon-squares:before {
  content: "\70";
}
.icon-sort:before {
  content: "\71";
}
.icon-setting:before {
  content: "\73";
}
.icon-menu-setting:before {
  content: "\74";
}
.icon-search:before {
  content: "\75";
}
.icon-info:before {
  content: "\76";
}
.icon-ring:before {
  content: "\77";
}
.icon-right-arrows-double:before {
  content: "\78";
}
.icon-right-align:before {
  content: "\79";
}
.icon-plus:before {
  content: "\41";
}
.icon-switch-off:before {
  content: "\42";
}
.icon-switch-on:before {
  content: "\43";
}
.icon-technical-support:before {
  content: "\7a";
}
.icon-tools:before {
  content: "\44";
}
.icon-user:before {
  content: "\45";
}
.icon-view:before {
  content: "\46";
}
.icon-branding:before {
  content: "\62";
}
.icon-trash:before {
  content: "\64";
}
.icon-home:before {
  content: "\68";
}
.icon-super-admin:before {
  content: "\72";
}
.icon-dashboard:before {
  content: "\47";
}
.icon-speedometer:before {
  content: "\48";
}
.icon-checkmark:before {
  content: "\49";
}
.icon-menu-o:before {
  content: "\4a";
}
.icon-menu-1:before {
  content: "\4b";
}
.icon-stop:before {
  content: "\4c";
}
.icon-more:before {
  content: "\4d";
}
.icon-more-o:before {
  content: "\4e";
}
.icon-error:before {
  content: "\50";
}
.icon-floppy-disk:before {
  content: "\51";
}
.icon-download:before {
  content: "\52";
}
.icon-loading:before {
  content: "\53";
}
.icon-clients:before {
  content: "\54";
}
.icon-cross-out:before {
  content: "\4f";
}
.icon-next:before {
  content: "\55";
}
.icon-back:before {
  content: "\56";
}
.icon-download-o:before {
  content: "\57";
}
.icon-mail:before {
  content: "\58";
}
.icon-a-z:before {
  content: "\59";
}
.icon-z-a:before {
  content: "\5a";
}
.icon-add-user:before {
  content: "\30";
}
.icon-ui:before {
  content: "\31";
}
.icon-arrow:before {
  content: "\32";
}
.icon-back-1:before {
  content: "\34";
}
.icon-log-file:before {
  content: "\33";
}
.icon-timetable:before {
  content: "\35";
}
.icon-tw:before {
  content: "\36";
}
.icon-fb:before {
  content: "\37";
}
.icon-youtube:before {
  content: "\38";
}
.icon-linkedin:before {
  content: "\39";
}
