.url {
  legend {
    display: inline-block;
    padding: 0 10px;
    font-size: 1.11rem;
    width: auto;
  }
}
.client-settings-container {
  display: flex;
  flex-direction: column;
  padding: 0;
  @include mq(lg) {
    flex-direction: row;
    flex: 1;
  }
}
.client-settings {
  margin-top: 0;
}

.client-settings {
  @include mq(lg) {
    width: calc(100% - 270px);
  }
}
.settings-container {
  display: flex;
  flex-direction: column;
  @include mq(md) {
    flex-direction: row;
  }

  .membership_charge {
    .custom-select,
    .form-control {
      padding: 0 10px;
      height: 30px !important;
    }

    .sm-selectWrap:before {
      top: 14px;
    }
  }

  label {
    font-weight: bold;
  }
  fieldset {
    width: 100%;
    padding: 0.35em 1em 0.75em;
  }
  legend {
    width: auto;
    padding: 0 10px;
    font-size: 18px;
  }
  iframe {
    border: none;
  }
  .brandsettings {
    margin-bottom: 30px;
    @include mq(md) {
      width: calc(100% - 350px);
      margin-bottom: 0;
    }
    .col-xs-6 {
      width: 50%;
    }
    .video-upload {
      display: flex;
      flex-direction: column;
      @include mq(md) {
        flex-direction: row;
      }
      .video-url {
        @include mq(md) {
          width: calc(100% - 240px);
        }
        video {
          width: 100%;
          margin-top: 5px;
          height: 300px;
        }
      }
      .custom-inputbox {
        margin: 0 auto;
        height: 25px;
        line-height: 24px;
        @include mq(md) {
          margin-left: auto;
        }
      }
      span {
        text-align: center;
        @include mq(md) {
          margin-left: 50px;
        }
      }
    }
  }

  .menusettings {
    @include mq(md) {
      width: 330px;
      margin-left: auto;
    }
    h3 {
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: bold;
      i {
        font-size: 12px;
        margin-left: 5px;
      }
    }
    .menu-content {
      // margin-bottom: 1rem;
      .form-group {
        margin-bottom: 5px;
        &:first-child {
          input {
            font-size: 16px;
            font-weight: 500;
            height: 100%;
          }
        }
        input {
          transition: 0.2s linear;
          font-size: 15px;
          height: calc(1.1em + 0.7rem + 1.5px);

          &:active,
          &:focus,
          &:hover {
            background: rgba(245, 245, 245, 0.3);
            border: 1px solid #1e1e1e;
            cursor: pointer;
            padding-left: 10px;
          }
          &:disabled {
            background: transparent;
            border: none !important;
            color: #a9a9a9;
            padding-left: 0;
            display: none;
          }
        }
      }
    }
  }

  .credit-payment {
    .input-group {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-align: stretch;
      align-items: stretch;
      width: 100%;
    }
    .input-group > .form-control,
    .input-group > .form-control-plaintext,
    .input-group > .custom-select,
    .input-group > .custom-file {
      position: relative;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      width: 1%;
      min-width: 0;
      margin-bottom: 0;
    }
    .form-control {
      display: block;
      width: 100%;
      // height: calc(1.5em + 0.75rem + 2px);
      // padding: 0.375rem 0.75rem;
      // font-size: 1rem;
      // font-weight: 400;
      // line-height: 1.5;
      // color: #495057;
      // background-color: #fff;
      // background-clip: padding-box;
      // border: 1px solid #ced4da;
      // border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .input-group-append {
      margin-left: -1px;
    }
    .input-group-prepend,
    .input-group-append {
      display: -ms-flexbox;
      display: flex;
    }
    .input-group > .form-control:not(:last-child), .input-group > .custom-select:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
  }
    .btn {
      // display: inline-block;
      // font-weight: 400;
      // color: #212529;
      // text-align: center;
      // vertical-align: middle;
      // -webkit-user-select: none;
      // -moz-user-select: none;
      // -ms-user-select: none;
      // user-select: none;
      // background-color: transparent;
      // border: 1px solid transparent;
      // padding: 0.375rem 0.75rem;
      // font-size: 1rem;
      // line-height: 1.5;
      // border-radius: 0.25rem;
      // transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      //   box-shadow 0.15s ease-in-out;
    }
    .btn-secondary {
      color: #212529;
      background-color: #f8f9fa;
      border-color: #f8f9fa;
    }
  }
}
.customcolorpicker {
  border: 1px solid #dddddd;
  position: relative;
  padding: 0.375rem;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  &:hover,
  &:focus {
    background: #f8f8f8;
    border-color: #ff8121;
  }
  .color {
    width: 42px;
    height: 42px;
    border-radius: 3px;
    display: block;
    border: 1px solid #dddddd;
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.08);
  }
  .colorcode {
    padding: 0 10px;
    line-height: 42px;
  }
  input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    height: 100%;
    cursor: pointer;
  }
  .color-display {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    background: #ddd;
    float: left;
    margin-right: 10px;
  }
}
.settings-footer {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  justify-content: flex-end;
  .btn {
    width: 100px;
    margin-left: 10px;
    padding: 3px 0;
  }
}
.on-off-menu {
  margin-top: 20px;
  .form-group {
    margin-bottom: 0.5rem;
  }
  label {
    font-weight: normal;
  }
}
.bg-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 244px;
  width: 100%;
}
.img-uploader {
  position: relative;
  width: 100%;
  height: auto;
  // min-width: 135px;
  padding: 5px;
  border: 2px dashed #dddddd;
  max-width: 100%;
  text-align: center;
  vertical-align: middle;
  // display: table-cell;
  min-height: 70px;

  input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .icon {
    position: absolute;
    top: -12px;
    right: -18px;
    background: #ffffff;
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
    width: 28px;
    height: 28px;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    display: none;
  }
  .img-upload-hover {
    background: #f8f8f8;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 10%;
    width: 100%;
    height: 100%;
    // margin: 0 auto;
    cursor: pointer;
    display: none;
    transition: 300ms ease-in;
  }
  &:hover .icon {
    display: block;
  }
  &:hover .img-upload-hover {
    display: block;
  }
  &.favicon {
    // height: 62px;
    top: -5px;
  }
}
.img-uploader > img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  text-align: center;
  display: inline-block;
}

.no-gutter > [class*="col-"] {
  padding-right: 0 !important;
  padding-left: 0 !important;
}