.client-add-edit-modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 99;
  overflow-y: auto;
  // @include mq(md) {
  //     position: fixed;
  //     top: 57px;
  //     left: 92px;
  // }
  .modal-header {
    background: transparent;
    border-bottom: none;
    justify-content: flex-end;
    i {
      float: right;
      font-size: 20px;
      width: 46px;
      height: 46px;
      text-align: center;
      vertical-align: middle;
      border-radius: 42px;
      padding: 5px 2px;
      &:hover {
        background: #dddddd;
        cursor: pointer;
      }
    }
  }
  .modal-body {
    width: 450px;
    margin: 0 auto;
    max-width: 100%;
    padding: 0 1rem 1rem;
    @include mq(md) {
      padding: 0 0 5rem;
    }
    h2 {
      margin-bottom: 30px;
      font-size: 25px;
      font-weight: bold;
    }
    .modal-form {
      label {
        font-weight: bold;
      }
      .custom-input {
        width: calc(100% - 120px);
        float: left;
      }
      .uploaded-logo {
        width: 120px;
        height: 120px;
        object-fit: contain;
        background: #ffffff;
        border: 1px solid #dddddd;
        box-sizing: border-box;
        border-radius: 3px;
      }

      .custom-select {
        display: inline-block;
        width: 100%;
        height: calc(2.25rem + 2px);
        padding: 0.375rem 1.75rem 0.375rem 0.75rem;
        line-height: 1.5;
        color: #495057;
        vertical-align: middle;
        background-size: 8px 10px;
        border: none;
        border-radius: 0.25rem;
        appearance: none;
      }

      .custom-select {
        margin-top: 10px;
        padding: 5px;
        height: 26px;
        font-size: 14px;
        line-height: 14px;
        border-radius: 0;
        border-bottom: 1px solid #d0d0d0;
        background: #fff;
        &:focus {
          box-shadow: none;
          border-bottom: 1px solid #ff8121;
        }
      }
      .sm-selectWrap {
        position: relative;
        width: 100%;

        &:before {
          content: "\67";
          font-family: baseplatform;
          position: absolute;
          top: 42px;
          right: 5px;
          text-align: center;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.5);
          background-color: #fff;
          pointer-events: none;
        }
      }

      .rdtPicker {
        width: auto;
      }
    }
  }
}
.custom-inputbox {
  border: 1px solid #666666;
  border-radius: 30px;
  width: 135px;
  position: relative;
  padding: 0 3px;
  text-align: center;
  cursor: pointer;
  input {
    position: absolute;
    width: 100%;
    opacity: 0;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    cursor: pointer;
  }
  &:hover {
    background: #666666;
    color: #fff;
  }
}
