.cross-out {
  background: #fff;
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 15px;
  font-size: 1.2rem;
  z-index: 9;
  cursor: pointer;
  opacity: 1;
  padding: 4px 0;
  color: #666;
  width: 42px;
  height: 42px;
  text-align: center;
  border-radius: 42px;
  &:hover {
    color: #000;
    background: #ddd;
  }
  .icon {
    margin: 0;
  }
}
.emailTemplate {
  background: #edf0f3;
  // max-width: 600px;
  // margin: 20px auto;
  width: 100%;
  td {
    padding: 15px;
    vertical-align: middle;
  }
  thead {
    .logo {
      width: auto;
      height: 70px;
    }
    ul {
      li {
        display: inline-block;
        a {
          background: #fff;
          text-decoration: none;
          display: inline-block;
          border-radius: 32px;
          width: 28px;
          height: 28px;
          text-align: center;
          padding: 3px 1px 3px 5px;
          font-size: 14px;
          margin: 0 3px;
          &last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 30px;
        p {
          font-size: 14px;
          &:first-child {
            font-weight: 500;
            font-size: 1.1rem;
          }
        }
        .btn-login {
          color: #fff;
          padding: 10px 30px;
          display: inline-block;
          margin-bottom: 15px;
        }
        .meltoLink {
          text-decoration: underline;
          margin-bottom: 15px;
          display: inline-block;
        }
      }
    }
  }
  tfoot {
    border-top: 1px solid #c7c7c7;
    .copyRight {
      font-size: 13px;
      color: #333;
      margin-bottom: 0;
    }
    ul {
      li {
        display: inline-block;
        a {
          font-size: 13px;
          padding: 0 7px;
          display: inline-block;
        }
      }
    }
  }
}
.emailSetting {
  label {
    font-size: 15px;
    font-weight: 500;
  }
  input {
    margin-bottom: 5px;
  }

  .sm-selectWrap {
    &:before {
      top: 7px;
    }
  }

}
