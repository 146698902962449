.card {
    margin-bottom: 20px;
    border-radius: 0;
}
.card-head {
    padding: 15px;
    display: flex;
    .card-title {
        font-size: 20px;
        color: $text-color;
        margin: 0;
        span {
            font-size: 12px;
            margin-left: 5px;
            color: #6c6c6c;
        }
    }
    .card-link {
        margin-left: auto;
        font-size: 14px;
        color: $link-color;
        align-self: center;
        align-items: center;
        display: flex;
        &:hover{
            color: #ff8121;
        }
        img {
            margin-right: 3px;
        }
    }
}
.card-body {
    padding: 0 15px;
}

.card-footer {
    background: $white;
    padding: 0;
}

.chart-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    color: #818181;
    overflow: hidden;
    span {
        &.name {
            font-size: 16px;
            margin-bottom: 2px;
            color: #000000;
        }
        &.count {
            font-size: 28px;
            font-weight: bold;
        }
    }
    &:first-child {
        border-right: 1px solid #ddd;
    }
    @include mq(md) {
        &:first-child {
            border-bottom: none;
            border-right: 1px solid #ddd;
        }
    }
}
