.superadmin-section {
  display: flex;
  flex-direction: column;
  // margin-top: 50px;
  @include mq(md) {
    flex-direction: row;
  }
}
.superadmin-list-container {
  background: #f4f5f7;
  height: 30vh;

  max-width: 100%;
  position: relative;
  border-right: 1px solid #e3e3e3;
  overflow: hidden;
  @include mq(md) {
    width: 320px;
    height: 95vh;
    margin-bottom: -7em;
  }
  .scrollbar-container {
    position: static !important;
    top: 62px !important;
    border-top: 1px solid #e3e3e3;

    @include mq(md) {
      position: absolute !important;
      right: 0;
      left: 0;
    }
  }
  .searchbar {
    margin: 15px 30px;
    position: relative;
    input {
      width: 100%;
      border: 1px solid #d9d9d9;
      padding: 3px 15px 3px 30px;
      height: 35px;
      border-radius: 30px;
      &:focus {
        outline: #000;
        box-shadow: 1px 1px 3px #d8d8d8;
      }
    }
    &:before {
      content: '\75';
      position: absolute;
      left: 10px;
      font-family: 'baseplatform' !important;
      color: #444444;
      bottom: 6px;
    }
  }
}
.superadmin-list {
  padding-bottom: 100px;

  .superadmin-user {
    display: flex;
    flex-direction: row;
    // border-bottom: 1px solid #dee2e6;
    padding: 10px 10px 10px 30px;
    flex: 1;
    cursor: pointer;
    &:last-child {
      border: none;
    }
    &:hover,
    &.active {
      background: #d6e8f9;
    }
    .user-img {
      width: 42px;
      height: 42px;
      border-radius: 100px;
      overflow: hidden;
      border: 1px solid #c4c4c4;
      padding: 1px;
      margin-right: 15px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .user {
      flex-direction: column;
      width: calc(100% - 75px);
      overflow: hidden;
      span {
        display: block;
        font-size: 13px;
      }
      .name {
        color: #000000;
        font-size: 14px;
      }
      .created-date {
        // color: #ddd;
        font-size: 12px;
      }
    }
  }
}

.superadmin-edit {
  flex: 1;
  padding: 20px 25px;
  @include mq(md) {
    margin-left: auto;
    margin-left: 30px;
    margin-top: 0;
    padding: 20px 25px 25px 0;
  }
  .head {
    border-bottom: 1px solid #cccccc;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    justify-content: center;
    h2 {
      font-weight: bold;
      font-size: 20px;
      align-self: center;
    }
    .button-container {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      .btn {
        margin-left: 10px;
      }
    }
  }
}
.edit-profile {
  margin-top: 70px;
  // .form-group {
  //   margin-bottom: 35px;
  // }
  .profileimg-uploader {
    /*     display: flex;
flex-direction: row; */
    margin: 10px;
  }
  .image-uploaded {
    width: 120px;
    height: 120px;
    border-radius: 3px;
    border: 2px solid rgba(221, 221, 221, 0.5);
    margin-right: 10px;
    border-radius: 100px;
    display: inline-block;
    padding: 2px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100px;
      object-fit: cover;
    }
  }
  span {
    display: block;
    &.name {
      font-size: 1.4rem;
      text-transform: capitalize;
      font-weight: 600;
    }
  }

  .custom-inputbox {
    align-self: center;
  }
  .status-container {
    display: block;
  }
  .status {
    width: 140px;
    border: 1px solid #cccccc;
    border-radius: 30px;
    padding: 5px 10px;
    position: relative;
    justify-content: center;
    display: flex;
    span {
      padding: 0 10px;
      font-size: 12px;
      font-weight: bold;
      border-radius: 30px;
    }
    .inactive {
      //color: #fff;
      //background: #fd0000;
    }
    input {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      &:checked {
        & + .active {
          background: #ff8121;
          color: #fff;
        }
        & + .inactive {
          color: #878787 !important;
          background: transparent !important;
        }
      }
    }
  }

  .btn-add {
    margin: 0 auto;
  }
}
