// ==========================================================================
// Base
// A Base rule is applied to HTML element using an element selector, a
// descendent selector, or a child selector, along with any pseudo-classes.
// We cannot reference any element defined here by their respective class or ID name.
// This defines the default styling for how that element should look.
// Changes in style other than default should be overwritten using other partials.
// ==========================================================================

// Reset CSS
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none !important;

    &:after {
        // Global Clearfixing
        clear: both;
        content: "";
        display: block;
    }
}

// Base Code
html,
body {
    font-size: 100%;
    // letter-spacing: 0.93px;
    background: $white;
    overflow-x: hidden;
}

body {
    -webkit-font-smoothing: antialiased;
    color: $color-text;
    font-family: $font-family--primary;
    text-rendering: optimizeLegibility;
}

.container{
    max-width: 1200px;
}

a {
    outline: none;
    color: $color-link;
    text-decoration: none;
    transition: color .2s;
    cursor: pointer;

    &:active {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }
}

a,
span,
strong {
    &:after {
        @extend %clearfix-none;
    }
}

ul,
ol {
    list-style: none;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-family--primary;
    margin: 0;
}

h1 {
    font-size: $head1-font-size;
}

h2 {
    font-size: $head2-font-size;
}

h3 {
    font-size: $head3-font-size;
}

img {
    max-width: 100%;
    vertical-align: top;
}

p {
    font-size: 17px;
    color: $dark-gray;
}

