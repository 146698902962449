.sidebar {
  width: 92px;
  background: #1e1e1e;
  height: 100vh;
  color: $white;
  padding-top: 5px;
  position: fixed;
  left: 0;
  z-index: 9999;
  display: none;
  @include mq(md) {
    position: relative;
    display: block;
    position: fixed;
  }
  img{  
    .logo {
    text-align: center;
    width: 100%;
    padding: 3px;
    padding-bottom: 10px;
      
    }
  }
  .logo>a{
    display: block;
    padding: 3px 10px;
    @include mq(md) {
      border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    }
  }
  .navigation-bar {
    padding: 0;
    ul {
      flex-direction: column;
      width: 100%;
      li > a {
        display: flex;
        flex-direction: column;
        padding: 5px 5px 15px;
        text-align: center;
        color: $white;
        span {
          font-size: 13px;
        }
        .icon {
          font-size: 38px;
          margin-right: 0;
          &:before {
            line-height: 1.2;
            vertical-align: bottom;
          }
        }
        &:hover, &.active, &[aria-expanded="true"] {
          background: $white;
          color: $black;
        }
      }
    }
  }
}
.sub-menu {
  background: #fff;
  padding: 10px 15px;
  width: 270px;
  border-radius: 4px;
  box-shadow: 3px 0px 10px hsla(0, 0%, 0%, 0.31);
  @include mq(md){
    width: 300px;
  }
  .submenu-footer {
    a {
      color: #656565;
      &:hover {
        color: #000;
      }
    }
  }
}
.d-block {
  display: block !important;
}
.d-none {
  display: none !important;
}
