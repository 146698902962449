.checkbox {
  position: relative;
  display: inline-block;
  font-size: 10px;
  &:before,
  &:after {
    color: #fff;
    position: absolute;
    bottom: 7px;
  }
  &:after {
    content: 'off';
    right: 5px;
  }
  &:before {
    content: 'on';
    left: 5px;
    z-index: 1;
  }
  label {
    width: 44px;
    height: 20px;
    background: #bababa;
    position: relative;
    display: inline-block;
    border-radius: 46px;
    transition: 0.4s;
    margin-bottom: 0;
    &:after {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transition: 0.4s;
    }
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
    &:hover + label:after {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2),
        0 3px 8px 0 rgba(0, 0, 0, 0.15);
    }
    &:checked + label {
      background: #1e1e1e;
      &:after {
        left: 22px;
      }
    }
    &:checked + &:before {
      content: 'yes';
    }
  }
}

.checkbox-super-admin-status {
  position: relative;
  display: inline-block !important;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 20px;
  &:before,
  &:after {
    color: #fff;
    position: absolute;
    bottom: 7px;
  }
  &:after {
    content: 'Inactive';
    right: 5px;
  }
  &:before {
    content: 'Active';
    left: 10px;
    z-index: 1;
  }
  label {
    width: 115px;
    height: 24px;
    background: #bababa;
    position: relative;
    display: inline-block;
    border-radius: 46px;
    transition: 0.4s;
    margin-bottom: 0;
    border:2px solid #e3e3e3;
    &:after {
      content: '';
      position: absolute;
      // width: 70px;
      width: 62px;
      height: 20px;
      border-radius: 22px;
      left: 0;
      top: 0;
      z-index: 2;
      background: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      transition: 0.4s;
    }
  }
  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
    &:hover + label:after {
      box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2),
        0 3px 8px 0 rgba(0, 0, 0, 0.15);
    }
    &:checked + label {
      background: #1e1e1e;
      &:after {
        // left: 46px;
        left: 49px;
      }
    }
    &:checked + &:before {
      content: 'yes';
    }
  }
}
