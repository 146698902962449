.log-form {
  .form-control,
  .custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    vertical-align: middle;
    background-size: 8px 10px;
    border: none;
    border-radius: 0.25rem;
    appearance: none;
  }

  .form-control,
  .custom-select {
    margin-top: 10px;
    padding: 5px;
    height: 26px;
    font-size: 14px;
    line-height: 14px;
    border-radius: 0;
    border-bottom: 1px solid #d0d0d0;
    background: #fff;
    &:focus {
      box-shadow: none;
      border-bottom: 1px solid #ff8121;
    }
  }
  .sm-selectWrap {
    position: relative;
    width: 100%;

    &:before {
      content: '\67';
      font-family: baseplatform;
      position: absolute;
      top: 14px;
      right: 5px;
      text-align: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      background-color: #fff;
      pointer-events: none;
    }
  }
  .btn-filter {
    display: inline-block;
    padding: 5px 25px;
    border-radius: 0;
    background: #ff8121;
    border: none;
  }
  .date {
    .form-control {
      padding-left: 20px;
    }
    &:after {
      content: '\35';
      font-family: 'baseplatform';
      position: absolute;
      bottom: 3px;
      left: 0;
      color: #343434;
      font-size: 14px;
    }
  }
}

.width-100 {
  width: 100%;
}
