// ==========================================================================
// Config
// Define variables, maps and fonts at this partial.
// ==========================================================================

//Enter your Web Address here for print media
$domain-name: 'www.domain-name.com';

//Text
$base__font-size: 16px;
$base__line: 24px;

//Font Stacks

//$font-url--google : abc;

// $typefaces
// Configuration of webfonts imports.
// $typefaces - {map}
// @family - {string}
// @weight - {integer} or {keyword} i.e. 800, bold
// @style - {keyword} i.e. normal, italic

//$typefaces: (
//  'FILENAME': (
//    family: 'fontname',
//    weight: 100,
//    style: normal,
//  ),
//);

$font-family-sans-fallback: 'Arial', sans-serif;
$font-family-sans: 'Lato', 'Source Sans Pro', 'Helvetica Neue', 'Helvetica',
  $font-family-sans-fallback;
$font-family-serif: 'Georgia', 'Cambria', 'Times New Roman', 'Times', serif;
$font-family-monospace: 'Inconsolata', 'Menlo', 'Consolas',
  'Bitstream Vera Sans Mono', 'Courier', monospace;
$font-family--primary: $font-family-sans;
$font-family--secondary: $font-family-sans;

//Colors
$white: #fff;
$black: #000;
$dark-gray: #2a2a2a;
$gray: #777777;
$light-gray: #9b9b9b;
$red: #d24d57;
$blue: #3a539b;
$yellow: #f7ca18;
$green: #36d7b7;
$primary: #ff8121;
$text-color: #343434;
$link-color: #656565;

//Message Colors
$error: lighten($red, 5%) !default;
$success: $green !default;
$edit: $yellow !default;

//Breakpoints
$sm-device: 768px;
$md-device: 992px;
$lg-device: 1200px;

//UI Colors
$ui-colors: (
  default: $blue,
  success: $success,
  error: $error,
  warning: $edit,
);

//Color Palette
$palettes: (
  gray-shades: (
    base: $gray,
    dark: darken($gray, 20%),
    light: lighten($gray, 20%),
  ),
  blue-shades: (
    base: $blue,
    dark: darken($blue, 20%),
    light: lighten($blue, 20%),
  ),
);

//Font size variables
$default-font-size: $base__font-size - 2px;
$small-font-size: $default-font-size - 2px;
$lg-font-size: $default-font-size + 2px;

//Heading font size vairables
$heading-line-height: 28px !default;
$head1-font-size: 26px !default;
$head2-font-size: $head1-font-size - 4px !default;
$head3-font-size: $head1-font-size - 8px !default;

//Color Usage
$primary-color: $white;
$secondary-color: $black;
$accent-color: $gray;
$shadow-color: rgba($black, 0.2);
$border-color: #d8d8d8;
$color-text: $black;
$color-link: $blue;

//Path to Assets
$path--rel: '../images';
