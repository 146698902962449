// ==========================================================================
// Style
// Import all manifest partials from other folder to this file.
//
// ---------------------------------------------------------------------------
//
// Scalable and Modular Architecture for CSS (SMACSS)
// With SMACSS, we divide our styles into fives categories: base, libs, modules, states, and utilities
// ==========================================================================

@import 'utilities/manifest';

/* Sass Compiled Css Code Start */
@import 'fontawesome/fontawesome';
@import 'base/manifest';
@import 'layout/manifest';
@import 'modules/manifest';
@import 'libs/manifest';
@import 'states/manifest';
@import 'modules/clone';
@import 'shame';