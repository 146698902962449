.search-bar {
  width: 100%;
  padding-left: 25px;
  position: relative;
  border-bottom: 1px solid #d6d6d6;
  &:before {
    content: '\75';
    font-family: 'baseplatform' !important;
    position: absolute;
    left: 0;
    top: 7px;
  }
  input {
    width: 100%;
    border: none;
    height: 40px;
    &:focus {
      outline: none;
    }
  }
}
