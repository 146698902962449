.base-table {
  border: 1px solid #ddd;
  thead {
    background: #f8f8f8;
    font-size: 14px;
    th {
      border: none;
      padding: 0.5rem;
      &:first-child {
        border-right: 1px solid #ddd;
        width: 34px;
        text-align: center;
        display: none;
      }
    }
  }
  tbody {
    th,
    td {
      &:first-child {
        border-right: 1px solid #ddd;
        text-align: center;
        font-size: 14px;
        display: none;
      }
    }
    td {
      vertical-align: middle;
      font-size: 14px;
      padding: 0.25rem 0.5rem;

      .logo {
        margin-right: 5px;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        object-fit: contain;
        box-shadow: 1px 1px 5px #ddd;
      }
    }
  }
  .option {
    & > button {
      background: transparent;
      border: 1px solid #ddd;
      border-radius: 25px !important;
      padding: 9px 20px 0;
      line-height: 1;
      .icon {
        width: auto;
        color: #343434;
        font-size: 20px;
        line-height: 0.4;
        margin-right: 0;
        &:before {
          line-height: 0.4;
        }
      }
      &:hover,
      &:active,
      &:focus,
      &:not(:disabled):not(.disabled):active {
        background: $black;
        border: 1px solid #ddd;
        outline: none;
        box-shadow: none;
        color: $white;
        .icon {
          color: #fff;
        }
      }
    }
    a {
      color: $black;
    }
  }
}

.filter-options {
  margin-bottom: 20px;
  @include mq(md) {
    margin-top: 40px;
  }
  .form-group {
    margin-right: 25px;
    position: relative;
    width: 100%;
    margin: 0;
    @include mq(md) {
      width: 400px;
      &.searchbar {
        margin-right: 15px;
      }
      &.sorting {
        select {
          width: calc(100% - 77px);
          margin-left: auto;
        }
      }
    }
    &.searchbar {
      &:before {
        content: '\75';
        position: absolute;
        right: 10px;
        font-family: 'baseplatform' !important;
        color: #444444;
        bottom: 7px;
      }
    }
    &.sorting {
      span {
        position: absolute;
        right: 0px;
        font-family: 'baseplatform' !important;
        color: #444444;
        bottom: 0;
        padding: 4px 5px;
        top: 26px;
        cursor: pointer;
        @include mq(md) {
          bottom: 0;
          top: 0;
        }
      }
    }
  }
  label {
    margin-right: 5px;
    margin-bottom: 2px;
  }
  input,
  select {
    border: 1px solid #d9d9d9;
    padding: 3px 25px 3px 15px;
    height: 35px;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    &:focus {
      outline: #000;
      box-shadow: 1px 1px 3px #d8d8d8;
    }
  }
}

.clients-table {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #dddddd;
}
