
.list-group-item {
    padding: 0.15rem 1.25rem;
	border: none;
	border-left: 2px solid gray;
	margin: 5px 0;
	&:first-child {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
	&:last-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
	}
}
